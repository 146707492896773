'use strict';

var GetIntrinsic = require('../GetIntrinsic');

var $Math = GetIntrinsic('%Math%');

var ToNumber = require('./ToNumber');

var $isNaN = require('../helpers/isNaN');

var $isFinite = require('../helpers/isFinite');

var $sign = require('../helpers/sign');

var $floor = $Math.floor;
var $abs = $Math.abs; // http://www.ecma-international.org/ecma-262/5.1/#sec-9.4

module.exports = function ToInteger(value) {
  var number = ToNumber(value);

  if ($isNaN(number)) {
    return 0;
  }

  if (number === 0 || !$isFinite(number)) {
    return number;
  }

  return $sign(number) * $floor($abs(number));
};