'use strict';

var GetIntrinsic = require('../GetIntrinsic');

var $defineProperty = GetIntrinsic('%Object.defineProperty%', true);

if ($defineProperty) {
  try {
    $defineProperty({}, 'a', {
      value: 1
    });
  } catch (e) {
    // IE 8 has a broken defineProperty
    $defineProperty = null;
  }
}

var callBound = require('../helpers/callBound');

var $isEnumerable = callBound('Object.prototype.propertyIsEnumerable'); // eslint-disable-next-line max-params

module.exports = function DefineOwnProperty(IsDataDescriptor, SameValue, FromPropertyDescriptor, O, P, desc) {
  if (!$defineProperty) {
    if (!IsDataDescriptor(desc)) {
      // ES3 does not support getters/setters
      return false;
    }

    if (!desc['[[Configurable]]'] || !desc['[[Writable]]']) {
      return false;
    } // fallback for ES3


    if (P in O && $isEnumerable(O, P) !== !!desc['[[Enumerable]]']) {
      // a non-enumerable existing property
      return false;
    } // property does not exist at all, or exists but is enumerable


    var V = desc['[[Value]]']; // eslint-disable-next-line no-param-reassign

    O[P] = V; // will use [[Define]]

    return SameValue(O[P], V);
  }

  $defineProperty(O, P, FromPropertyDescriptor(desc));
  return true;
};