'use strict';

var GetIntrinsic = require('../GetIntrinsic');

var $TypeError = GetIntrinsic('%TypeError%');

var IsPropertyKey = require('./IsPropertyKey');

var Type = require('./Type'); // https://ecma-international.org/ecma-262/6.0/#sec-hasproperty


module.exports = function HasProperty(O, P) {
  if (Type(O) !== 'Object') {
    throw new $TypeError('Assertion failed: `O` must be an Object');
  }

  if (!IsPropertyKey(P)) {
    throw new $TypeError('Assertion failed: `P` must be a Property Key');
  }

  return P in O;
};