'use strict';

var GetIntrinsic = require('../GetIntrinsic');

var $TypeError = GetIntrinsic('%TypeError%');

var MAX_SAFE_INTEGER = require('../helpers/maxSafeInteger');

var Call = require('./Call');

var CreateDataPropertyOrThrow = require('./CreateDataPropertyOrThrow');

var Get = require('./Get');

var HasProperty = require('./HasProperty');

var IsArray = require('./IsArray');

var ToLength = require('./ToLength');

var ToString = require('./ToString'); // https://ecma-international.org/ecma-262/10.0/#sec-flattenintoarray
// eslint-disable-next-line max-params, max-statements


module.exports = function FlattenIntoArray(target, source, sourceLen, start, depth) {
  var mapperFunction;

  if (arguments.length > 5) {
    mapperFunction = arguments[5];
  }

  var targetIndex = start;
  var sourceIndex = 0;

  while (sourceIndex < sourceLen) {
    var P = ToString(sourceIndex);
    var exists = HasProperty(source, P);

    if (exists === true) {
      var element = Get(source, P);

      if (typeof mapperFunction !== 'undefined') {
        if (arguments.length <= 6) {
          throw new $TypeError('Assertion failed: thisArg is required when mapperFunction is provided');
        }

        element = Call(mapperFunction, arguments[6], [element, sourceIndex, source]);
      }

      var shouldFlatten = false;

      if (depth > 0) {
        shouldFlatten = IsArray(element);
      }

      if (shouldFlatten) {
        var elementLen = ToLength(Get(element, 'length'));
        targetIndex = FlattenIntoArray(target, element, elementLen, targetIndex, depth - 1);
      } else {
        if (targetIndex >= MAX_SAFE_INTEGER) {
          throw new $TypeError('index too large');
        }

        CreateDataPropertyOrThrow(target, ToString(targetIndex), element);
        targetIndex += 1;
      }
    }

    sourceIndex += 1;
  }

  return targetIndex;
};