'use strict';

var has = require('has');

var assertRecord = require('../helpers/assertRecord');

var Type = require('./Type'); // https://www.ecma-international.org/ecma-262/6.0/#sec-isdatadescriptor


module.exports = function IsDataDescriptor(Desc) {
  if (typeof Desc === 'undefined') {
    return false;
  }

  assertRecord(Type, 'Property Descriptor', 'Desc', Desc);

  if (!has(Desc, '[[Value]]') && !has(Desc, '[[Writable]]')) {
    return false;
  }

  return true;
};