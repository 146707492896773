import React from 'react';
import { jsx, useThemeUI, merge, Context } from '@theme-ui/core';
import { Global, ThemeContext } from '@emotion/core';
import { css, get } from '@theme-ui/css';

var toVarName = function toVarName(key) {
  return "--theme-ui-" + key;
};

var toVarValue = function toVarValue(key, value) {
  return "var(" + toVarName(key) + ", " + value + ")";
};

var join = function join() {
  var args = [],
      len = arguments.length;

  while (len--) {
    args[len] = arguments[len];
  }

  return args.filter(Boolean).join('-');
};

var numberScales = {
  fontWeights: true,
  lineHeights: true
};
var reservedKeys = {
  useCustomProperties: true,
  initialColorModeName: true,
  initialColorMode: true
};

var toPixel = function toPixel(key, value) {
  if (typeof value !== 'number') {
    return value;
  }

  if (numberScales[key]) {
    return value;
  }

  return value + 'px';
}; // convert theme values to custom properties


var toCustomProperties = function toCustomProperties(obj, parent, themeKey) {
  var next = Array.isArray(obj) ? [] : {};

  for (var key in obj) {
    var value = obj[key];
    var name = join(parent, key);

    if (value && typeof value === 'object') {
      next[key] = toCustomProperties(value, name, key);
      continue;
    }

    if (reservedKeys[key]) {
      next[key] = value;
      continue;
    }

    var val = toPixel(themeKey || key, value);
    next[key] = toVarValue(name, val);
  }

  return next;
};

var objectToVars = function objectToVars(parent, obj) {
  var vars = {};

  for (var key in obj) {
    if (key === 'modes') {
      continue;
    }

    var name = join(parent, key);
    var value = obj[key];

    if (value && typeof value === 'object') {
      vars = Object.assign({}, vars, objectToVars(name, value));
    } else {
      vars[toVarName(name)] = value;
    }
  }

  return vars;
}; // create body styles for color modes


var createColorStyles = function createColorStyles(theme) {
  if (theme === void 0) theme = {};

  if (!theme.colors || theme.useBodyStyles === false) {
    return {};
  }

  if (theme.useCustomProperties === false || !theme.colors.modes) {
    return css({
      body: {
        color: 'text',
        bg: 'background'
      }
    })(theme);
  }

  var colors = theme.rawColors || theme.colors;
  var modes = colors.modes;
  var styles = objectToVars('colors', colors);
  Object.keys(modes).forEach(function (mode) {
    var key = "&.theme-ui-" + mode;
    styles[key] = objectToVars('colors', modes[mode]);
  });
  return css({
    body: Object.assign({}, styles, {
      color: 'text',
      bg: 'background'
    })
  })(theme);
};

var STORAGE_KEY = 'theme-ui-color-mode';
var storage = {
  get: function get(init) {
    try {
      return window.localStorage.getItem(STORAGE_KEY) || init;
    } catch (e) {
      console.warn('localStorage is disabled and color mode might not work as expected.', 'Please check your Site Settings.', e);
    }
  },
  set: function set(value) {
    try {
      window.localStorage.setItem(STORAGE_KEY, value);
    } catch (e) {
      console.warn('localStorage is disabled and color mode might not work as expected.', 'Please check your Site Settings.', e);
    }
  }
};

var getMediaQuery = function getMediaQuery() {
  var darkQuery = '(prefers-color-scheme: dark)';
  var lightQuery = '(prefers-color-scheme: light)';
  var darkMQL = window.matchMedia ? window.matchMedia(darkQuery) : {};
  var lightMQL = window.matchMedia ? window.matchMedia(lightQuery) : {};
  var dark = darkMQL.media === darkQuery && darkMQL.matches;

  if (dark) {
    return 'dark';
  }

  var light = lightMQL.media === lightQuery && lightMQL.matches;

  if (light) {
    return 'light';
  }

  return 'default';
};

var useColorModeState = function useColorModeState(theme) {
  if (theme === void 0) theme = {};
  var ref = React.useState(theme.initialColorModeName || 'default');
  var mode = ref[0];
  var setMode = ref[1]; // initialize state

  React.useEffect(function () {
    var stored = storage.get();
    document.body.classList.remove('theme-ui-' + stored);

    if (!stored && theme.useColorSchemeMediaQuery) {
      var query = getMediaQuery();
      setMode(query);
      return;
    }

    if (!stored || stored === mode) {
      return;
    }

    setMode(stored);
  }, []);
  React.useEffect(function () {
    if (!mode) {
      return;
    }

    storage.set(mode);
  }, [mode]);

  if (process.env.NODE_ENV !== 'production') {
    if (theme.colors && theme.colors.modes && Object.keys(theme.colors.modes).indexOf(theme.initialColorModeName) > -1) {
      console.warn('The `initialColorModeName` value should be a unique name' + ' and cannot reference a key in `theme.colors.modes`.');
    }
  }

  return [mode, setMode];
};

var useColorMode = function useColorMode() {
  var ref = useThemeUI();
  var colorMode = ref.colorMode;
  var setColorMode = ref.setColorMode;

  if (typeof setColorMode !== 'function') {
    throw new Error("[useColorMode] requires the ColorModeProvider component");
  }

  return [colorMode, setColorMode];
};

var applyColorMode = function applyColorMode(theme, mode) {
  if (!mode) {
    return theme;
  }

  var modes = get(theme, 'colors.modes', {});
  return merge.all({}, theme, {
    colors: get(modes, mode, {})
  });
};

var BodyStyles = function BodyStyles() {
  return jsx(Global, {
    styles: function styles(theme) {
      return createColorStyles(theme);
    }
  });
};

var ColorModeProvider = function ColorModeProvider(ref) {
  var children = ref.children;
  var outer = useThemeUI();
  var ref$1 = useColorModeState(outer.theme);
  var colorMode = ref$1[0];
  var setColorMode = ref$1[1];
  var theme = applyColorMode(outer.theme || {}, colorMode);
  var emotionTheme = Object.assign({}, theme);

  if (theme.useCustomProperties !== false) {
    emotionTheme.colors = toCustomProperties(emotionTheme.colors, 'colors');
  }

  var context = Object.assign({}, outer, {
    theme: theme,
    colorMode: colorMode,
    setColorMode: setColorMode
  });
  return jsx(ThemeContext.Provider, {
    value: emotionTheme
  }, jsx(Context.Provider, {
    value: context
  }, jsx(BodyStyles, {
    key: 'color-mode'
  }), children));
};

var noflash = "(function() { try {\n  var mode = localStorage.getItem('theme-ui-color-mode');\n  if (!mode) return\n  document.body.classList.add('theme-ui-' + mode);\n} catch (e) {} })();";

var InitializeColorMode = function InitializeColorMode() {
  return jsx('script', {
    key: 'theme-ui-no-flash',
    dangerouslySetInnerHTML: {
      __html: noflash
    }
  });
};

export { useColorMode, ColorModeProvider, InitializeColorMode };