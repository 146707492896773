'use strict';

var powers = 0;
exports.boolean = increment();
exports.booleanish = increment();
exports.overloadedBoolean = increment();
exports.number = increment();
exports.spaceSeparated = increment();
exports.commaSeparated = increment();
exports.commaOrSpaceSeparated = increment();

function increment() {
  return Math.pow(2, ++powers);
}