'use strict';

var define = require('define-properties');

var callBind = require('es-abstract/helpers/callBind');

var implementation = require('./implementation');

var getPolyfill = require('./polyfill');

var polyfill = getPolyfill();

var shim = require('./shim');

var boundFlat = callBind(polyfill);
define(boundFlat, {
  getPolyfill: getPolyfill,
  implementation: implementation,
  shim: shim
});
module.exports = boundFlat;