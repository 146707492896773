'use strict';

var MAX_SAFE_INTEGER = require('../helpers/maxSafeInteger');

var ToInteger = require('./ToInteger');

module.exports = function ToLength(argument) {
  var len = ToInteger(argument);

  if (len <= 0) {
    return 0;
  } // includes converting -0 to +0


  if (len > MAX_SAFE_INTEGER) {
    return MAX_SAFE_INTEGER;
  }

  return len;
};