'use strict';

var GetIntrinsic = require('../GetIntrinsic');

var $match = GetIntrinsic('%Symbol.match%', true);

var hasRegExpMatcher = require('is-regex');

var ToBoolean = require('./ToBoolean'); // https://ecma-international.org/ecma-262/6.0/#sec-isregexp


module.exports = function IsRegExp(argument) {
  if (!argument || typeof argument !== 'object') {
    return false;
  }

  if ($match) {
    var isRegExp = argument[$match];

    if (typeof isRegExp !== 'undefined') {
      return ToBoolean(isRegExp);
    }
  }

  return hasRegExpMatcher(argument);
};