'use strict';

var GetIntrinsic = require('../GetIntrinsic');

var callBound = require('../helpers/callBound');

var $apply = GetIntrinsic('%Reflect.apply%', true) || callBound('%Function.prototype.apply%'); // https://www.ecma-international.org/ecma-262/6.0/#sec-call

module.exports = function Call(F, V) {
  var args = arguments.length > 2 ? arguments[2] : [];
  return $apply(F, V, args);
};