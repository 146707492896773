
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import csr from "./images/csr.png";
import ssr from "./images/ssr.png";
import ssg from "./images/ssg.png";
import isr from "./images/isr.png";
import next from "./images/next.svg";
import pros from "./images/pros.png";
import { CodeSurfer, Step } from "code-surfer";
import { nightOwl } from "@code-surfer/themes";

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Head = makeShortcode("Head");
const Steps = makeShortcode("Steps");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Head mdxType="Head">
	<title>WTH is ISR?</title>
	<meta name="twitter:card" content="summary_large_image" />
	<meta name="twitter:site" content="@smakosh" />
	<meta name="twitter:title" content="WTH is ISR?" />
	<meta name="twitter:description" content="A quick explanation of what incremental static regeneration is in Next js" />
	<meta name="twitter:image" content="https://2020.wth-isg.talks.smakosh.com/images/card.png" />
    </Head>
    <h1>{`Salam | Hello | مرحبا`}</h1>
    <hr></hr>
    <p>{`This talk is about`}</p>
    <h2>{`ISR || ISG || iSSG using Next.js`}</h2>
    <hr></hr>
    <p>{`What else will we talk about?`}</p>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`SSG vs SSR vs CSR vs ISR`}</li>
        <li parentName="ul">{`Explaining ISR`}</li>
        <li parentName="ul">{`Quick introduction to Next js`}</li>
        <li parentName="ul">{`getStaticProps, getStaticPaths, getServerSideProps`}</li>
        <li parentName="ul">{`Demo`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`Let's get started!`}</h2>
    <hr></hr>

    <h2>{`CSR`}</h2>
    <div style={{
      width: 900,
      height: 900
    }}>
	<img src={csr} />
    </div>
    <hr></hr>

    <h2>{`SSR`}</h2>
    <div style={{
      width: 900,
      height: 900
    }}>
	<img src={ssr} />
    </div>
    <hr></hr>

    <h2>{`SSG`}</h2>
    <div style={{
      width: 900,
      height: 900
    }}>
	<img src={ssg} />
    </div>
    <hr></hr>

    <h2>{`ISR`}</h2>
    <div style={{
      width: 900,
      height: 900
    }}>
	<img src={isr} />
    </div>
    <hr></hr>

    <div style={{
      width: 800,
      height: 800
    }}>
	<img src={next} />
    </div>
    <hr></hr>

    <div style={{
      width: 800,
      height: 800
    }}>
	<img src={pros} />
    </div>
    <hr></hr>
    <h2>{`getStaticProps, getStaticPaths, getServerSideProps`}</h2>
    <hr></hr>
    <CodeSurfer theme={nightOwl} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-js",
          "metastring": "8:19 title=\"index.js\"",
          "8:19": true,
          "title": "\"index.js\""
        }}>{`const HomePage = ({ products, categories }) => (
    <>
        <pre>{JSON.stringify(products, undefined, 2)}</pre>
        <pre>{JSON.stringify(categories, undefined, 2)}</pre>
    </>
);

export const getStaticProps = async () => {
    const products = await fetchProducts();
    const categories = await fetchCategories();

    return {
        props: {
            products,
            categories,
        },
        revalidate: 2,
    };
};

export default HomePage;
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-js",
          "metastring": "1:6 title=\"index.js\"",
          "1:6": true,
          "title": "\"index.js\""
        }}>{`const HomePage = ({ products, categories }) => (
    <>
        <pre>{JSON.stringify(products, undefined, 2)}</pre>
        <pre>{JSON.stringify(categories, undefined, 2)}</pre>
    </>
);

export const getStaticProps = async () => {
    const products = await fetchProducts();
    const categories = await fetchCategories();

    return {
        props: {
            products,
            categories,
        },
        revalidate: 2,
    };
};

export default HomePage;
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-js",
          "metastring": "title=\"index.js\"",
          "title": "\"index.js\""
        }}>{`const HomePage = ({ products, categories }) => (
    <>
        <pre>{JSON.stringify(products, undefined, 2)}</pre>
        <pre>{JSON.stringify(categories, undefined, 2)}</pre>
    </>
);

export const getStaticProps = async () => {
    const products = await fetchProducts();
    const categories = await fetchCategories();

    return {
        props: {
            products,
            categories,
        },
        revalidate: 2,
    };
};

export default HomePage;
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h2>{`getStaticProps, getStaticPaths`}</h2>
    <hr></hr>
    <CodeSurfer theme={nightOwl} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-js",
          "metastring": "12:22 title=\"[id].js\"",
          "12:22": true,
          "title": "\"[id].js\""
        }}>{`import { useRouter } from "next/router";

const ProductPage = ({ product }) => {
    const router = useRouter();

    if (router.isFallback) {
        return <h1>Loading...</h1>;
    }
    return <pre>{JSON.stringify(product, undefined, 2)}</pre>;
};

export const getStaticPaths = async () => {
    const products = await fetchProducts();
    const paths = products.map(({ id }) => ({
        params: { id },
    }));

    return {
        paths,
        fallback: true,
    };
};

export const getStaticProps = async ({ params: { id } }) => {
    const product = await fetchProductById(id);

    return {
        props: {
            product,
        },
        revalidate: 2,
    };
};

export default ProductPage;
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-js",
          "metastring": "24:33 title=\"[id].js\"",
          "24:33": true,
          "title": "\"[id].js\""
        }}>{`import { useRouter } from "next/router";

const ProductPage = ({ product }) => {
    const router = useRouter();

    if (router.isFallback) {
        return <h1>Loading...</h1>;
    }
    return <pre>{JSON.stringify(product, undefined, 2)}</pre>;
};

export const getStaticPaths = async () => {
    const products = await fetchProducts();
    const paths = products.map(({ id }) => ({
        params: { id },
    }));

    return {
        paths,
        fallback: true,
    };
};

export const getStaticProps = async ({ params: { id } }) => {
    const product = await fetchProductById(id);

    return {
        props: {
            product,
        },
        revalidate: 2,
    };
};

export default ProductPage;
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-js",
          "metastring": "title=\"[id].js\"",
          "title": "\"[id].js\""
        }}>{`import { useRouter } from "next/router";

const ProductPage = ({ product }) => {
    const router = useRouter();

    if (router.isFallback) {
        return <h1>Loading...</h1>;
    }
    return <pre>{JSON.stringify(product, undefined, 2)}</pre>;
};

export const getStaticPaths = async () => {
    const products = await fetchProducts();
    const paths = products.map(({ id }) => ({
        params: { id },
    }));

    return {
        paths,
        fallback: true,
    };
};

export const getStaticProps = async ({ params: { id } }) => {
    const product = await fetchProductById(id);

    return {
        props: {
            product,
        },
        revalidate: 2,
    };
};

export default ProductPage;
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h2>{`getServerSideProps`}</h2>
    <hr></hr>

    <CodeSurfer theme={nightOwl} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-js",
          "metastring": "8:19 title=\"index.js\"",
          "8:19": true,
          "title": "\"index.js\""
        }}>{`const HomePage = ({ products, categories }) => (
    <>
        <pre>{JSON.stringify(products, undefined, 2)}</pre>
        <pre>{JSON.stringify(categories, undefined, 2)}</pre>
    </>
);

export const getServerSideProps = async () => {
    const products = await fetchProducts();
    const categories = await fetchCategories();

    return {
        props: {
            products,
            categories,
        },
    };
};

export default HomePage;
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-js",
          "metastring": "1:6 title=\"index.js\"",
          "1:6": true,
          "title": "\"index.js\""
        }}>{`const HomePage = ({ products, categories }) => (
    <>
        <pre>{JSON.stringify(products, undefined, 2)}</pre>
        <pre>{JSON.stringify(categories, undefined, 2)}</pre>
    </>
);

export const getServerSideProps = async () => {
    const products = await fetchProducts();
    const categories = await fetchCategories();

    return {
        props: {
            products,
            categories,
        },
    };
};

export default HomePage;
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-js",
          "metastring": "title=\"index.js\"",
          "title": "\"index.js\""
        }}>{`const HomePage = ({ products, categories }) => (
    <>
        <pre>{JSON.stringify(products, undefined, 2)}</pre>
        <pre>{JSON.stringify(categories, undefined, 2)}</pre>
    </>
);

export const getServerSideProps = async () => {
    const products = await fetchProducts();
    const categories = await fetchCategories();

    return {
        props: {
            products,
            categories,
        },
    };
};

export default HomePage;
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h2>{`Demo time`}</h2>
    <hr></hr>
    <h2>{`Questions time!`}</h2>
    <hr></hr>
    <p>{`Refs:`}</p>
    <ul>
      <li parentName="ul">{`Next js Docs`}</li>
      <li parentName="ul">{`Ontwik.dev for demo`}</li>
      <li parentName="ul">{`MDX Deck and Code surf for slides`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://next-code-elimination.now.sh/"
        }}>{`https://next-code-elimination.now.sh/`}</a></li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;