'use strict';

var refractorPhp = require('./php.js');

module.exports = phpExtras;
phpExtras.displayName = 'phpExtras';
phpExtras.aliases = [];

function phpExtras(Prism) {
  Prism.register(refractorPhp);
  Prism.languages.insertBefore('php', 'variable', {
    this: /\$this\b/,
    global: /\$(?:_(?:SERVER|GET|POST|FILES|REQUEST|SESSION|ENV|COOKIE)|GLOBALS|HTTP_RAW_POST_DATA|argc|argv|php_errormsg|http_response_header)\b/,
    scope: {
      pattern: /\b[\w\\]+::/,
      inside: {
        keyword: /static|self|parent/,
        punctuation: /::|\\/
      }
    }
  });
}