'use strict';

require("core-js/modules/es.array.flat");

require("core-js/modules/es.array.unscopables.flat");

var implementation = require('./implementation');

module.exports = function getPolyfill() {
  return Array.prototype.flat || implementation;
};