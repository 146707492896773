'use strict';

var GetIntrinsic = require('../GetIntrinsic');

var $TypeError = GetIntrinsic('%TypeError%');

var isPropertyDescriptor = require('../helpers/isPropertyDescriptor');

var DefineOwnProperty = require('../helpers/DefineOwnProperty');

var FromPropertyDescriptor = require('./FromPropertyDescriptor');

var IsAccessorDescriptor = require('./IsAccessorDescriptor');

var IsDataDescriptor = require('./IsDataDescriptor');

var IsPropertyKey = require('./IsPropertyKey');

var SameValue = require('./SameValue');

var ToPropertyDescriptor = require('./ToPropertyDescriptor');

var Type = require('./Type'); // https://www.ecma-international.org/ecma-262/6.0/#sec-definepropertyorthrow


module.exports = function DefinePropertyOrThrow(O, P, desc) {
  if (Type(O) !== 'Object') {
    throw new $TypeError('Assertion failed: Type(O) is not Object');
  }

  if (!IsPropertyKey(P)) {
    throw new $TypeError('Assertion failed: IsPropertyKey(P) is not true');
  }

  var Desc = isPropertyDescriptor({
    Type: Type,
    IsDataDescriptor: IsDataDescriptor,
    IsAccessorDescriptor: IsAccessorDescriptor
  }, desc) ? desc : ToPropertyDescriptor(desc);

  if (!isPropertyDescriptor({
    Type: Type,
    IsDataDescriptor: IsDataDescriptor,
    IsAccessorDescriptor: IsAccessorDescriptor
  }, Desc)) {
    throw new $TypeError('Assertion failed: Desc is not a valid Property Descriptor');
  }

  return DefineOwnProperty(IsDataDescriptor, SameValue, FromPropertyDescriptor, O, P, Desc);
};