'use strict';

require("core-js/modules/es.array.flat");

require("core-js/modules/es.array.unscopables.flat");

var define = require('define-properties');

var getPolyfill = require('./polyfill');

module.exports = function shimFlat() {
  var polyfill = getPolyfill();
  define(Array.prototype, {
    flat: polyfill
  }, {
    flat: function flat() {
      return Array.prototype.flat !== polyfill;
    }
  });
  return polyfill;
};