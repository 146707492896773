'use strict';

var hasSymbols = require('has-symbols')();

var hasToStringTag = hasSymbols && typeof Symbol.toStringTag === 'symbol';
var hasOwnProperty;
var regexExec;
var isRegexMarker;
var badStringifier;

if (hasToStringTag) {
  hasOwnProperty = Function.call.bind(Object.prototype.hasOwnProperty);
  regexExec = Function.call.bind(RegExp.prototype.exec);
  isRegexMarker = {};

  var throwRegexMarker = function throwRegexMarker() {
    throw isRegexMarker;
  };

  badStringifier = {
    toString: throwRegexMarker,
    valueOf: throwRegexMarker
  };

  if (typeof Symbol.toPrimitive === 'symbol') {
    badStringifier[Symbol.toPrimitive] = throwRegexMarker;
  }
}

var toStr = Object.prototype.toString;
var gOPD = Object.getOwnPropertyDescriptor;
var regexClass = '[object RegExp]';
module.exports = hasToStringTag // eslint-disable-next-line consistent-return
? function isRegex(value) {
  if (!value || typeof value !== 'object') {
    return false;
  }

  var descriptor = gOPD(value, 'lastIndex');
  var hasLastIndexDataProperty = descriptor && hasOwnProperty(descriptor, 'value');

  if (!hasLastIndexDataProperty) {
    return false;
  }

  try {
    regexExec(value, badStringifier);
  } catch (e) {
    return e === isRegexMarker;
  }
} : function isRegex(value) {
  // In older browsers, typeof regex incorrectly returns 'function'
  if (!value || typeof value !== 'object' && typeof value !== 'function') {
    return false;
  }

  return toStr.call(value) === regexClass;
};