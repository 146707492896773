'use strict';

var refractorJson = require('./json.js');

module.exports = json5;
json5.displayName = 'json5';
json5.aliases = [];

function json5(Prism) {
  Prism.register(refractorJson);

  (function (Prism) {
    var string = /("|')(?:\\(?:\r\n?|\n|.)|(?!\1)[^\\\r\n])*\1/;
    Prism.languages.json5 = Prism.languages.extend('json', {
      property: [{
        pattern: RegExp(string.source + '(?=\\s*:)'),
        greedy: true
      }, {
        pattern: /[_$a-zA-Z\xA0-\uFFFF][$\w\xA0-\uFFFF]*(?=\s*:)/,
        alias: 'unquoted'
      }],
      string: {
        pattern: string,
        greedy: true
      },
      number: /[+-]?(?:NaN|Infinity|0x[a-fA-F\d]+|(?:\d+\.?\d*|\.\d+)(?:[eE][+-]?\d+)?)/
    });
  })(Prism);
}