'use strict'; // https://www.ecma-international.org/ecma-262/5.1/#sec-8

module.exports = function Type(x) {
  if (x === null) {
    return 'Null';
  }

  if (typeof x === 'undefined') {
    return 'Undefined';
  }

  if (typeof x === 'function' || typeof x === 'object') {
    return 'Object';
  }

  if (typeof x === 'number') {
    return 'Number';
  }

  if (typeof x === 'boolean') {
    return 'Boolean';
  }

  if (typeof x === 'string') {
    return 'String';
  }
};