'use strict';

var has = require('has');

var GetIntrinsic = require('../GetIntrinsic');

var $TypeError = GetIntrinsic('%TypeError%');

var Type = require('./Type');

var ToBoolean = require('./ToBoolean');

var IsCallable = require('./IsCallable'); // https://ecma-international.org/ecma-262/5.1/#sec-8.10.5


module.exports = function ToPropertyDescriptor(Obj) {
  if (Type(Obj) !== 'Object') {
    throw new $TypeError('ToPropertyDescriptor requires an object');
  }

  var desc = {};

  if (has(Obj, 'enumerable')) {
    desc['[[Enumerable]]'] = ToBoolean(Obj.enumerable);
  }

  if (has(Obj, 'configurable')) {
    desc['[[Configurable]]'] = ToBoolean(Obj.configurable);
  }

  if (has(Obj, 'value')) {
    desc['[[Value]]'] = Obj.value;
  }

  if (has(Obj, 'writable')) {
    desc['[[Writable]]'] = ToBoolean(Obj.writable);
  }

  if (has(Obj, 'get')) {
    var getter = Obj.get;

    if (typeof getter !== 'undefined' && !IsCallable(getter)) {
      throw new TypeError('getter must be a function');
    }

    desc['[[Get]]'] = getter;
  }

  if (has(Obj, 'set')) {
    var setter = Obj.set;

    if (typeof setter !== 'undefined' && !IsCallable(setter)) {
      throw new $TypeError('setter must be a function');
    }

    desc['[[Set]]'] = setter;
  }

  if ((has(desc, '[[Get]]') || has(desc, '[[Set]]')) && (has(desc, '[[Value]]') || has(desc, '[[Writable]]'))) {
    throw new $TypeError('Invalid property descriptor. Cannot both specify accessors and a value or writable attribute');
  }

  return desc;
};